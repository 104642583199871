<script>
import Vue from 'vue';

export default {
  name: 'ConfirmEmail',
  mounted() {
    localStorage.setItem('token', this.$route.query.token)
    this.$http.post(API_ROOT + `/api/auth/email/confirm`, {token: this.$route.query.token})
      .then((res) => {
        localStorage.setItem('has_email', true)
        localStorage.setItem('token', res.body.token)

        this.$http
          .get(API_ROOT + "/api/user")
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res.body))

            Vue.toastr({
              message: this.$t('Success'),
              description: res.body.message,
              type: 'success'
            })
            // this.$router.push({name: 'profile'});
            window.location = "/profile";
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {})
  },
}
</script>
